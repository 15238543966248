.static {
  position: relative;
  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.618);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
}
