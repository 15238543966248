@import '../../../../../assets/scss/index';

.registration-fee {
  &__waiver-period {
    padding-bottom: 14px;
    .ant-typography {
      @include text-base;
      color: $--color-text-secondary;
    }
  }
  .form-item-hor {
    .ant-form-item-control-input {
      min-height: initial;
    }
  }
}
