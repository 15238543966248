@import '../../assets/scss/variables';

$offset: 187;
$duration: 1.4s;
$--color-dark: #000 default;

.splash-screen {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner {
    animation: rotator $duration linear infinite;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  stroke: $--color-dark;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite,
    colors ($duration * 4) ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

@keyframes colors {
  0% {
    stroke: $--color-dark;
  }
  50% {
    stroke: $--color-primary;
  }
  100% {
    stroke: #2f82e2;
  }
}
