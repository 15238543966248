@import '../../../assets/scss/index';

.pa-log-meta {
  h2 {
    @include text-bold($--font-size-medium);
    color: $--color-primary;
    text-align: center;
    margin-bottom: 40px;
  }
  .text-bold {
    color: $--color-text-bold;
    margin-bottom: 12px;
  }
  .text {
    @include text-base;
    margin-bottom: 0;
  }
  .label {
    @include text-base;
    color: $--color-text-secondary;
    margin-bottom: 0;
  }
}
