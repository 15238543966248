.user-pagination {
  .ant-pagination-item {
    border: none;
    background: transparent !important;
  }

  .ant-pagination-item-active {
    // background: #2b368b !important;
    // border-radius: 25px;
    a {
      color: #2b368b;
      font-weight: bold;
    }
  }
  .ant-pagination-options-quick-jumper {
    > input {
      height: 36px;
      background-color: #fff;
    }
  }
}
