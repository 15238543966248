.indigo-pill {
  min-height: 24px;
  height: auto;
  width: fit-content;
  text-align: center;
  color: #fff;
  border-radius: 24px;
  padding: 4px 10px;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
}

.success-pill {
  background-color: #65db75;
}

.warning-pill {
  background-color: #ffa629;
}

.primary-pill {
  background-color: #538dff;
}

.danger-pill {
  background-color: #f54f64;
}

.brown-pill {
  background-color: #828894;
}

.purple-pill {
  background-color: #6d48e5;
}

.info-pill {
  background-color: #538dff;
}

.withdrawn-pill {
  background-color: #f54f64;
}

.graduated-pill {
  background-color: #fa73c4;
}

.draff-pill {
  background-color: rgb(130, 136, 148);
}
