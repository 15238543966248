@import './typography';

.create-pa {
  &__group {
    margin-top: 40px;
  }

  &__title {
    @include text-bold($--font-size-medium);
    color: $--color-text-bold;
    margin: 0;
  }

  &__content {
    padding: 20px;
    width: 100%;
    border: 1px solid rgb(216, 220, 230);
  }
}

.ant-form-label {
  margin-top: 10px;
}

.ant-form-item .ant-form-item-label {
  padding-bottom: 5px;

  > label {
    font-size: 14px;
  }
}

.report-filter {
  .ant-form-item-control {
    min-height: 90px;
  }
  .ant-btn {
    margin-top: 26px;
  }
}
