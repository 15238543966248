@import '../../assets/scss/index.scss';
@import '../../assets/scss/variables.scss';

.header-wrap {
  border-bottom: 1px solid $--color-line;
}

.header-title {
  color: $--color-primary;
  font-size: 18px;
  margin-right: 2rem;
  font-weight: bolder;
  margin-bottom: 1.5rem;
  align-items: center;
}

.financeinvoice,
.financepayment-advice,
.financereceipt,
.financecredit-note,
.financerefund,
.financedelete-batch-payment-advice,
.financepayment-setting,
.financepayment-discount,
.financepricing,
.reportenrolment-summary,
.reportrevenue-breakdown,
.reportnumber-of-classes,
.reportpercentage-of-class,
.reportaverage-enrollment-per-class,
.reportnew-sign-up-student,
.attendancegantry-record,
.reportweekly-replacement,
.reportgeneral-replacement,
.reporttransfer,
.reportclassroom-utilisation,
.reportcapacity-utilisation {
  .ant-breadcrumb {
    > span:first-child {
      display: none;
    }
  }
}

.reportsuspension,
.reportwithdrawal {
  .ant-breadcrumb {
    > span {
      display: none;

      &:last-child {
        display: block;
      }
    }
  }
}
