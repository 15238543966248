@import '../../../../../assets/scss/index';

.transfer-info {
  &__reason {
    position: relative;
    padding: 12px 16px;
    border: 1px solid $--color-line;
    border-radius: 4px;
    background-color: #ffffff;
    margin-bottom: 20px;
    margin-top: 16px;
    .label {
      @include text-base;
      color: $--color-text-secondary;
      margin-bottom: 0;
    }
    .text {
      @include text-base;
      color: $--color-text-bold;
      margin-bottom: 0;
      margin-left: 8px;
    }
  }
  p {
    margin-bottom: 0;
  }
}
