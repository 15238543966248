@import '../../../../../assets/scss/index';

.create-payment-advice-class-card {
  border: 1px solid rgb(216, 220, 230);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .ant-col {
    position: static;
  }

  .remove-card {
    position: absolute;
    right: 0;
    top: 13px;
    min-width: 20px !important;
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    padding: 0 20px;
  }

  .lesson-date {
    padding: 0 20px;
  }

  .payment-advice-block {
    padding: 16px;
  }

  .border-top {
    border-top: 1px solid rgb(216, 220, 230);
  }

  .border-right {
    border-right: 1px solid rgb(216, 220, 230);
  }

  .discount {
    &__wrapper {
      max-height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__list {
      flex: 1;
      overflow-y: auto;
    }

    &__item {
      padding: 10px 20px 0;

      &::after {
        content: '';
        height: 1px;
        width: 100%;
        background-color: rgb(216, 220, 230);
      }

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        border-bottom: 0;

        &::after {
          content: none;
        }
      }
    }

    &__remove {
      color: $--color-dangerous;
      padding: 0;
      display: block;
      min-width: 20px !important;
      padding-top: 40px !important;
    }

    &__add {
      width: 100%;
      border-color: rgb(216, 220, 230);
      color: $--color-tag;
    }
  }

  .ant-select-multiple .ant-select-selection-item-content {
    font-size: 12px;
  }
}
