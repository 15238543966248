.class-list-popup {
  top: 0;
  height: 100%;
  padding-bottom: 0;
  overflow: hidden;

  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .ant-modal-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      //   .has-scroll {
      //     flex: 1;
      //     overflow: hidden;

      //     .ant-table-wrapper,
      //     .ant-spin-nested-loading,
      //     .ant-spin-container,
      //     .ant-table,
      //     .ant-table-container {
      //       height: 100%;
      //     }

      //     .ant-table-container {
      //       display: flex;
      //       flex-direction: column;

      //       .ant-table-body {
      //         max-height: initial;
      //       }
      //     }
      //   }
      // }
      .table-container {
        max-height: 400px;
        overflow: auto;
      }
    }
  }
}
