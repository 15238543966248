@import '../../../src/assets/scss/index';

.navigation {
  .anticon {
    font-size: 20px !important;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  background-color: $--color-primary;
  min-height: 460px;

  &__auth {
    transition: 0.2s;
    position: relative;
    padding-left: 40px;
    padding: 0 16px 0 56px;
    margin-top: 20px;

    .ant-avatar {
      position: absolute;
      top: 4px;
      left: 16px;
    }

    .ant-row {
      display: inline;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    p {
      margin: 0;

      .ant-tag {
        margin: 0;
      }
    }

    .text {
      @include text-base;
      color: #fff;
      line-height: 1;
      margin-bottom: 4px;

      &--sub {
        margin-top: 4px;
        @include text-small;
      }
    }
  }

  .ant-btn {
    margin: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 40px;
    width: calc(100% - 32px);
    background-color: rgba(#000, 0.22);
    color: #fff;
    border: none;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logout-btn {
    background-color: #394bd7;

    &.icon {
      min-width: 48px !important;
    }
  }

  &__footer {
    width: 100%;

    &__text {
      @include text-small;
      color: #538dff;
      text-align: center;
      margin-top: 16px;
    }
  }

  .ant-menu-dark {
    background-color: $--color-primary;

    .ant-menu-inline.ant-menu-sub {
      background-color: $--color-primary;
    }

    .ant-menu-item {
      &:last-child {
        margin-bottom: 0;
      }

      &-selected {
        background-color: rgba(#000, 0.22);
      }
    }

    .ant-menu-submenu-open {
      color: #fff !important;
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
}

.ant-menu-inline {
  .ant-menu-inline {
    .ant-menu-inline {
      .ant-menu-item {
        margin-left: -25px !important;
        width: 225px;
      }
    }
  }
}

.ant-menu-submenu-popup {
  .ant-menu-sub {
    background-color: $--color-primary;

    .ant-menu-submenu {
      span {
        color: #fff;
      }

      &-arrow {
        &::after,
        &::before {
          background: #fff;
        }
      }
    }

    .ant-menu-item {
      a {
        color: #fff;
      }

      &:hover {
        color: #fff !important;
      }
    }
  }

  .ant-menu-item {
    margin: 0;

    li {
      margin: 0;
    }

    &-selected {
      background-color: rgba(#000, 0.22) !important;
    }

    &-active {
      background-color: rgba(#000, 0.22);
    }

    span {
      color: #fff;
    }
  }

  .ant-menu-submenu-selected {
    background-color: rgba(#000, 0.22);
  }

  .ant-menu-submenu-open {
    &.ant-menu-submenu-active {
      .ant-menu-submenu-title {
        color: #fff !important;
      }
    }
  }
}

.collapse-icon {
  width: 20px;
  display: flex;
  color: #000000;
  margin-top: 50px;

  svg {
    width: 24px !important;
    height: 24px;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    z-index: 1000;
    border: 1px solid #2b368b;
    right: -10px;
  }
}

.logo {
  width: 244px;
}

.img-logo {
  transition: 0.3s ease;
  height: 30px;
  width: 50px;
  margin: 47px auto 32px auto;
}

.expand-logo {
  height: 60px !important;
  width: auto;
  margin: 32px auto 32px auto;
}

.profile-info {
  transition: 0.3s ease;
}

.profile-info-hide {
  opacity: 0;
}

.navigation__footer {
  overflow: hidden;
  max-height: 1000px;
  transition: 0.3s ease;
}

.navigation__footer-hide {
  max-height: 0;
}

.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
