@import './variables';
$--font-name: 'Inter-Regular';
$--font-name-bold: 'Inter-SemiBold';

@mixin font-family-regular {
  font-family: $--font-name;
}

@mixin font-family-medium {
  font-family: $--font-name;
}

@mixin font-family-bold {
  font-family: $--font-name-bold;
}

@mixin text-small($font-size: $--font-size-small) {
  @include font-family-regular;
  font-size: $font-size;
}

@mixin text-base($font-size: $--font-size-base) {
  @include font-family-regular;
  font-size: $font-size;
}

@mixin text-medium($font-size: $--font-size-medium) {
  @include font-family-medium();
  font-size: $font-size;
}

@mixin text-bold($font-size: $--font-size-large) {
  @include font-family-bold();
  font-size: $font-size;
}

@mixin text-base-italic($font-size: $--font-size-base) {
  @include text-base($font-size);
  font-style: italic;
}
